import Dashboard from "../Admin/Dashboard"
import AddMerchant from "../Admin/AddMerchant"
import requireAuth from "../Components/HOC/requireAuth"
import { AREA_PREFIX, BANNER_PREFIX, ALL_ORDERS, CATEGORY_PREFIX, DASHBOARD, MERCHANT_PREFIX, PRODUCT_PREFIX, STORE_MENU, STORE_PREFIX, ORDER_PREFIX, FLEET_PREFIX, TEAM_PREFIX, F_D_CHANGE,COUPON_PREFIX,REVIEW_PREFIX,GIFT_PREFIX,PUSHNOTICE_PREFIX,CUSTOMER_PREFIX } from "../utils/Constants"
import MerchantList from "../Admin/Merchants"
import AddStore from "../Admin/AddStore"
import Areas from "../Admin/Areas"
import AddCity from "../Admin/AddCity"

import Coupons from "../Admin/Coupons"
import AddCoupon from "../Admin/AddCoupon"

import GiftCards from "../Admin/GiftCards"
import AddGiftCards from "../Admin/AddGiftCards"


import PushNotifications from "../Admin/PushNotifications"
import AddPushNotifications from "../Admin/AddPushNotifications"

import Reviews from "../Admin/Reviews"
import AddReview from "../Admin/AddReview"

import AddRoute from "../Admin/AddRoute"
import { ROUTE_PREFIX } from "../utils/Constants"
import Route from "../Admin/Route"
import Stores from "../Admin/Store"
import AddCategory from "../Admin/AddCategory"
import Categories from "../Admin/Categories"
import ProductCsvUploader from "../Admin/ProductCsvUploader"
import ProductsList from "../Admin/Products"

import CustomersList from "../Admin/Customers"
import NotificationCustomersList from "../Admin/NotificationCustomers"
import CardDetails from "../Admin/CardDetails"
import ReedemPointLogs from "../Admin/rewardPoints"
import orderCommentsFile from "../Admin/OrderComments"
import StoreMenu from "../Admin/StoreMenu"
import AddStoreMenu from "../Admin/AddStoreMenu"
import AddBanner from "../Admin/AddBanner"
import BannerList from "../Admin/Banners"
import OrderList from "../Admin/Orders";
import OrderProductsList from "../Admin/OrdersProducts";
import OrderDetail from "../Admin/OrderDetail"
import FleetList from "../Admin/Fleets"
// Support Tickets
import TicketList from "../Admin/Tickets"
import ReportsList from "../Admin/Reports"
import ReportsMonthlyList from "../Admin/MonthlyReports"
import ReportsCustomersList from "../Admin/CustomersReports"
import ReportsProductsList from "../Admin/ProductsReports"
import AddFleet from "../Admin/AddFleet"
// Comment Support Tickets
import AddSupportComment from "../Admin/AddSupportComment"
import CommentTicket from "../Admin/CommentTicket"
import AddStaff from "../Admin/AddStaff"
import TeamMember from "../Admin/TeamMember"
import ChangeDeliveryFleetDriver from "../Admin/ChangeDeliveryFleetDriver"
import ChangeLog from "../Admin/ChangeLog"
import OrderEdit from "../Admin/OrderEdit"
import ProductEdit from "../Admin/ProductEdit"
import ProductAdd from "../Admin/ProductAdd"
import CustomerEditDetails from "../Admin/CustomerEdit"

const UserDashboard = requireAuth(Dashboard, "Dashboard")
const MerchantListing = requireAuth(MerchantList, "ListMerchant")
const AddNewMerchant = requireAuth(AddMerchant, "AddMerchant")
const UpdateMerchant = requireAuth(AddMerchant, "EditMerchant")
const AddCityForm = requireAuth(AddCity, "AddCity")
const UpdateCityForm = requireAuth(AddCity, "EditCity")
const CityListing = requireAuth(Areas, "CityListing")
const AddRouteForm = requireAuth(AddRoute, "AddRoute")
const UpdateRouteForm = requireAuth(AddRoute, "EditRoute")

const CouponListing = requireAuth(Coupons, "CouponListing")
const AddCouponForm = requireAuth(AddCoupon, "AddCoupon")
const UpdateCouponForm = requireAuth(AddCoupon, "EditCoupon")

const GiftCardsListing = requireAuth(GiftCards, "GiftCardsListing")
const AddGiftCardsForm = requireAuth(AddGiftCards, "AddGiftCards")

const PushNotificationsListing = requireAuth(PushNotifications, "PushNotificationsListing")
const PushNotificationsForm = requireAuth(AddPushNotifications, "AddPushNotifications")

const ReviewListing = requireAuth(Reviews, "ReviewListing")
const UpdateReviewForm = requireAuth(AddReview, "EditReview")

const RouteListing = requireAuth(Route, "RouteListing")
const FleetListing = requireAuth(FleetList, "FleetListing")
const TicketListing= requireAuth(TicketList, "FleetListing")
const ReportsListing = requireAuth(ReportsList, "FleetListing")
const MonthlyReportsListing = requireAuth(ReportsMonthlyList, "FleetListing")
const CustomersReportsListing = requireAuth(ReportsCustomersList, "FleetListing")
const ProductsReportsListing = requireAuth(ReportsProductsList, "FleetListing")
const AddFleetForm = requireAuth(AddFleet, "AddFleet")
// support Ticket
const AddNewTicket = requireAuth(AddSupportComment, "AddFleet")
const AddTicketComment = requireAuth(CommentTicket, "AddFleet")
const UpdateFleetForm = requireAuth(AddFleet, "EditFleet")
const MemberListing = requireAuth(TeamMember, "TeamListing")
const AddTeamForm = requireAuth(AddStaff, "AddTeam")
const UpdateTeamForm = requireAuth(AddStaff, "EditTeam")
const FleetDriverChangeForm = requireAuth(ChangeDeliveryFleetDriver, "ChangeDeliveryFleetDriver")
const FleetDriverChangeList = requireAuth(ChangeLog, "AuditLogListing")
const AddStoreForm = requireAuth(AddStore, "AddStore")
const UpdateStoreForm = requireAuth(AddStore, "EditStore")
const StoreListing = requireAuth(Stores, "ListStore")
const AddCategoryForm = requireAuth(AddCategory, "AddCategory")
const UpdateCategoryForm = requireAuth(AddCategory, "EditCategory")
const CategoryListing = requireAuth(Categories, "ListCategory")
const ProductCSVUpload = requireAuth(ProductCsvUploader, "UploadProductCSV")
const ProductListing = requireAuth(ProductsList, "ListProduct")

const CustomerListing = requireAuth(CustomersList, "ListProduct")
const NotificationCustomerListing = requireAuth(NotificationCustomersList, "ListProduct")
const CustomerRewardPoints = requireAuth(ReedemPointLogs, "ListProduct")
const OrderComments = requireAuth(orderCommentsFile, "OrderDetails")
const CustomerEdit = requireAuth(CustomerEditDetails, "ListProduct")

const StoreMenuAddForm = requireAuth(AddStoreMenu, "AddMenu")
const StoreMenuUpdateForm = requireAuth(AddStoreMenu, "EditMenu")
const StoreMenuListing = requireAuth(StoreMenu, "ListMenu")
const AddBannerForm = requireAuth(AddBanner, "AddBanner")
const UpdateBannerForm = requireAuth(AddBanner, "EditBanner")
const BannerListing = requireAuth(BannerList, "ListingBanner")
const OrderListing = requireAuth(OrderList, "ListOrder")
const OrderProductsListing = requireAuth(OrderProductsList, "ListOrder")
const CardDetail = requireAuth(CardDetails, "CardDetails")
const OrderDetailing = requireAuth(OrderDetail, "OrderDetails")
export default [
    {
        path: `${DASHBOARD}`,
        component: <UserDashboard/>
    },
    {
        path: `${MERCHANT_PREFIX}/:useraction/:id`,
        component: <UpdateMerchant/>
    },
    {
        path: `${MERCHANT_PREFIX}/:useraction/`,
        component: <AddNewMerchant/>
    },
    {
        path: `${MERCHANT_PREFIX}`,
        component: <MerchantListing/>
    },
    {
        path: `${AREA_PREFIX}/:useraction/:id`,
        component: <UpdateCityForm/>
    },
    {
        path: `${AREA_PREFIX}/:useraction/`,
        component: <AddCityForm/>
    },
    {
        path: `${AREA_PREFIX}`,
        component: <CityListing/>
    },
	{
        path: `${COUPON_PREFIX}/:useraction/:id`,
        component: <UpdateCouponForm/>
    },
    {
        path: `${COUPON_PREFIX}/:useraction/`,
        component: <AddCouponForm/>
    },
    {
        path: `${COUPON_PREFIX}`,
        component: <CouponListing/>
    },
	{
        path: `${GIFT_PREFIX}/:useraction/`,
        component: <AddGiftCardsForm/>
    },
    {
        path: `${GIFT_PREFIX}`,
        component: <GiftCardsListing/>
    },
	{
        path: `${PUSHNOTICE_PREFIX}/:useraction/`,
        component: <AddPushNotifications/>
    },
    {
        path: `${PUSHNOTICE_PREFIX}`,
        component: <PushNotificationsListing/>
    },
	{
        path: `${REVIEW_PREFIX}/:useraction/:id`,
        component: <UpdateReviewForm/>
    },
    {
        path: `${REVIEW_PREFIX}`,
        component: <ReviewListing/>
    },
    {
        path: `${ROUTE_PREFIX}/:useraction/:id`,
        component: <UpdateRouteForm/>
    },
    {
        path: `${ROUTE_PREFIX}/:useraction/`,
        component: <AddRouteForm/>
    },
    {
        path: `${ROUTE_PREFIX}`,
        component: <RouteListing/>
    },
    {
        path: `${FLEET_PREFIX}/:useraction/:id`,
        component: <UpdateFleetForm/>
    },
    {
        path: `${FLEET_PREFIX}/:useraction/`,
        component: <AddFleetForm/>
    },
    {
        path: `${FLEET_PREFIX}`,
        component: <FleetListing />
    },
	{
        path: `/support-tickets`,
        component: <TicketListing />
    },
	{
        path: `/support-tickets/comments/:ticketId`,
        component: <AddTicketComment/>
    },
	{
        path: `/support-tickets/:useraction/`,
        component: <AddNewTicket/>
    },
	{
        path: `/weekly-reports`,
        component: <ReportsListing />
    },
	{
        path: `/monthly-reports`,
        component: <MonthlyReportsListing />
    },
	{
        path: `/customers-reports`,
        component: <CustomersReportsListing />
    },
	{
        path: `/products-reports`,
        component: <ProductsReportsListing />
    },
    {
        path: `${TEAM_PREFIX}/:useraction/:id`,
        component: <UpdateTeamForm/>
    },
    {
        path: `${TEAM_PREFIX}/:useraction/`,
        component: <AddTeamForm/>
    },
    {
        path: `${TEAM_PREFIX}`,
        component: <MemberListing/>
    },
    {
        path: `${F_D_CHANGE}/:useraction/`,
        component: <FleetDriverChangeForm/>
    },
    {
        path: `${F_D_CHANGE}`,
        component: <FleetDriverChangeList/>
    },
    {
        path: `${STORE_PREFIX}/:store${STORE_MENU}/:useraction/:maincat`,
        component: <StoreMenuUpdateForm />
    },
    {
        path: `${STORE_PREFIX}/:store${STORE_MENU}/:useraction`,
        component: <StoreMenuAddForm />
    },
    {
        path: `${STORE_PREFIX}/:store${STORE_MENU}`,
        component: <StoreMenuListing />
    },
    {
        path: `${STORE_PREFIX}/:useraction/:id`,
        component: <UpdateStoreForm/>
    },
    {
        path: `${STORE_PREFIX}/:useraction`,
        component: <AddStoreForm/>
    },
    {
        path: `${STORE_PREFIX}`,
        component: <StoreListing/>
    },
    {
        path: `${CATEGORY_PREFIX}/:useraction/:id`,
        component: <UpdateCategoryForm/>
    },
    {
        path: `${CATEGORY_PREFIX}/:useraction`,
        component: <AddCategoryForm/>
    },
    { 
        path: `${CATEGORY_PREFIX}`,
        component: <CategoryListing/>
    },
    {
        path: `${PRODUCT_PREFIX}/csv`,
        component: <ProductCSVUpload />
    },
    {
        path: `${PRODUCT_PREFIX}`,
        component: <ProductListing/>
    },
	{
        path: `${CUSTOMER_PREFIX}`,
        component: <CustomerListing/>
    },
	{
        path: `${PUSHNOTICE_PREFIX}/customers/:id`,
        component: <NotificationCustomerListing/>
    },
	{
        path: `${CUSTOMER_PREFIX}/reward-points/:id`,
        component: <CustomerRewardPoints/>
    },	
	{
        path: `${CUSTOMER_PREFIX}/:useraction/:id`,
        component: <CustomerEdit/>
    },
    {
        path: `${BANNER_PREFIX}/:useraction/:id`,
        component: <UpdateBannerForm/>
    },
    {
        path: `${BANNER_PREFIX}/:useraction`,
        component: <AddBannerForm/>
    },
    {
        path: `${BANNER_PREFIX}`,
        component: <BannerListing/>
    },
    {
        path: `${ORDER_PREFIX}/edit/:orderID`,
        component: <OrderEdit/>
    },
	{
        path: `${ORDER_PREFIX}/comments/:orderID`,
        component: <OrderComments/>
    },
    {
        path: `${ORDER_PREFIX}/:orderID`,
        component: <OrderDetailing/>
    },
    {
        path: `${ORDER_PREFIX}`,
        component: <OrderListing/>
    },
	{
        path: `${ORDER_PREFIX}/order-products`,
        component: <OrderProductsListing/>
    },
	{
        path: `customer-card-details/:cardId`,
        component: <CardDetail/>
    },
    {
        path: `${PRODUCT_PREFIX}/edit/:productId`,
        component: <ProductEdit/>
    },
    {
        path: `${PRODUCT_PREFIX}/create`,
        component: <ProductAdd/>
    },
]